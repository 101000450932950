@font-face {
  font-family: Segoe UI;
  src:
      local("Segoe UI"),
      url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff2) format("woff2"),
      url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff) format("woff"),
      url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.ttf) format("truetype");
  font-weight: 400;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  font-family: "Inter", sans-serif;
  text-decoration: none;
  color: #000000;
}

/* Notifications */
.Toastify__toast {
  font-family: "Inter", sans-serif;
}
.Toastify__toast-body {
  white-space: pre-line;
}
.Toastify__toast--info {
  background-color: #edf9ff;
}
.Toastify__toast--success {
  background-color: #4da621;
}
.Toastify__toast--warning {
  background-color: #f77c00;
}
.Toastify__toast--error {
  background-color: #d71d24;
}

/* Breadcrumbs */
#breadcrumb-popover .MuiBox-root {
  padding: 0;
}

#breadcrumb-popover .MuiPopover-paper .MuiPaper-elevation4 {
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.4);
}

#breadcrumb-popover .MuiDivider-root {
  display: none;
}

#breadcrumb-popover .MuiGrid-item {
  padding-top: 23px;
  padding-bottom: 7px;
  min-width: 270px;
}

#breadcrumb-popover .MuiTypography-subtitle2 {
  padding-left: 30px;
  margin-bottom: 5px;
}

#breadcrumb-popover .MuiListItem-root {
  padding-left: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
}

#breadcrumb-popover .MuiListItem-root::before {
  content: " ";
  height: 1px;
  position: absolute;
  top: 0;
  right: 0;
  width: calc(100% - 30px);
  background: rgba(0, 0, 0, 0.1);
}

#breadcrumb-popover .MuiGrid-item:first-child {
  background: #d8e0e7;
}

#breadcrumb-popover .MuiGrid-item:first-child .MuiListItem-root.Mui-selected,
#breadcrumb-popover
  .MuiGrid-item:first-child
  .MuiListItem-root.Mui-selected:hover {
  background: #ecf0f3;
}

#breadcrumb-popover .MuiGrid-item:nth-child(2) {
  background: rgba(216, 224, 231, 0.5);
}

#breadcrumb-popover .MuiGrid-item:nth-child(2) .MuiListItem-root.Mui-selected,
#breadcrumb-popover
  .MuiGrid-item:nth-child(2)
  .MuiListItem-root.Mui-selected:hover {
  background: #ffffff;
}

#breadcrumb-popover
  .MuiGrid-item:first-child
  .MuiListItem-root.Mui-selected
  .MuiTypography-body2,
#breadcrumb-popover
  .MuiGrid-item:first-child
  .MuiListItem-root.Mui-selected:hover
  .MuiTypography-body2,
#breadcrumb-popover
  .MuiGrid-item:nth-child(2)
  .MuiListItem-root.Mui-selected
  .MuiTypography-body2,
#breadcrumb-popover
  .MuiGrid-item:nth-child(2)
  .MuiListItem-root.Mui-selected:hover
  .MuiTypography-body2 {
  color: black;
}

#breadcrumb-popover .MuiGrid-item:last-child .MuiListItem-root.Mui-selected,
#breadcrumb-popover
  .MuiGrid-item:last-child
  .MuiListItem-root.Mui-selected:hover {
  background: white;
}

#breadcrumb-popover
  .MuiGrid-item:nth-child(n+3)
  .MuiListItem-root.Mui-selected
  .MuiTypography-body2,
#breadcrumb-popover
  .MuiGrid-item:nth-child(n+3)
  .MuiListItem-root.Mui-selected:hover
  .MuiTypography-body2 {
  font-weight: bold;
  color: black;
  font-size: 18px;
}

#datepicker-popper .MuiPaper-elevation4 {
  border-radius: 8px;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.4) !important;
  padding-top: 0;
}

#datepicker-popper .MuiBox-root {
  border-radius: 8px 8px 0 0;
}

#datepicker-popper .MuiPaper-root > .MuiBox-root {
  position: unset;
}

#datepicker-popper #from-to-filter ~ .MuiList-root {
  padding-top: 0px;
}

/* Safari-specific table styles (MIN-2154) */

_::-webkit-full-page-media,
_:future,
:root .MuiTableRow-root::before,
:root .MuiTableRow-root::after {
  content: none;
}

_::-webkit-full-page-media,
_:future,
:root .MuiTableRow-root.MuiTableRow-hover {
  transition: background-color 300ms ease-in-out,
}

_::-webkit-full-page-media,
_:future,
:root .MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: #FAFAFA
}

_::-webkit-full-page-media,
_:future,
:root .MuiTableCell-body {
  border-bottom: 1px solid #E0E5EC;
}